import React, { PureComponent } from 'react';
import {Accordion, AccordionSummary, AccordionDetails, Typography, withStyles} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import '../Sass/Accord.scss'

const useStyle = (theme) => ({
Accord : {
  backgroundColor: 'rgb(231, 231, 231)',
},
AccordTypo : {
  fontWeight: 600,
  color: "rgb(71, 71, 71)",
},
accordDetail: {
  display: 'flex',
  flexDirection: "column",
}
})
class Accord extends PureComponent {
    render() {
      const {classes} = this.props
        return (
            <>
        <Accordion className={classes.Accord}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id={this.props.id}
        >
          <Typography className={classes.AccordTypo} >{this.props.name}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordDetail}>
          {this.props.children}
        </AccordionDetails>
      </Accordion>
            </>
        );
    }
}

export default withStyles(useStyle)(Accord);
import React, { PureComponent } from 'react';
import Typography from '@material-ui/core/Typography'
import { Paper, Container, InputBase, IconButton, Divider, Button } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import SearchIcon from '@material-ui/icons/Search';
import '../Sass/Main.scss';
import Filter from './Filter';
import Card1 from './Card';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchCardsByPage, fetchSubCatCards, fetchCards, fetchSubCatCardsByPage, fetchCardsBySearch } from '../Redux/Card/CardActions';
import withProtection from './withProtection';

class Main extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            search: ''
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.props.fetchCards()
    }
    handleSearch = (e) => {
        this.setState({
            search: e.target.value
        })
    }
    handlePage = (e, value) => {
        this.props.fetchCardsByPage(this.props.Card.subID, value)

    }
    render() {
        const { cards, cardLoading } = this.props.Card
        let cardList = cards.map((card) => {
            return (
                <Link key={card.id} to={`worksheet/${card.id}/`}><Card1 key={card.id} id={card.id} src={card.worksheet_image_url} name={card.worksheet_name} /></Link>
            )
        })
        if (cardLoading) {
            return (
                <div className='Loading'>
                    <CircularProgress />
                </div>
            )
        }
        return (
            <Container>
                <center>
                    <Typography
                        color='primary'
                        variant='h3'
                        component='h1'
                        gutterBottom
                        className='heading'
                    >
                        Free Worksheets and Printables for Kids
                    </Typography></center>
                <center>
                    <Paper elevation={3} className='paper'>
                        <InputBase placeholder='Search' className='input' name='search' value={this.state.search} onChange={this.handleSearch} />
                        <IconButton onClick={() => this.props.fetchCardsBySearch(this.state.search)} >
                            <SearchIcon fontSize='large' color='primary' />
                        </IconButton>
                    </Paper>
                </center>
                <Divider />
                <div className='main'>
                    <div className='filter'>
                        <Filter />
                    </div>
                    <div className='cards'>
                        {cardList}
                    </div>

                </div>
                <div className='pagination'><Pagination count={this.props.Card.totalPages} page={Number(this.props.Card.page)} onChange={this.handlePage} variant="outlined" color="primary" size='large' /></div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        Card: state.Card
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCards: () => dispatch(fetchCards()),
        fetchCardsByPage: (id, page) => dispatch(fetchCardsByPage(id, page)),
        fetchSubCatCards: (id) => dispatch(fetchSubCatCards(id)),
        fetchCardsBySearch: (name) => dispatch(fetchCardsBySearch(name))
    }
}

export default withProtection(connect(mapStateToProps, mapDispatchToProps)(Main));
import React, { PureComponent } from 'react'
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { Button, Chip, CircularProgress, Typography, withStyles } from '@material-ui/core'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import PrintIcon from '@material-ui/icons/Print';
import withProtection from './withProtection';
const useStyles = () => ({
    Details: {
        padding: '2% 5%'
    },
    worksheet: {
        color: 'gray',
        display: 'flex',
        alignItems: 'center'
    },
    worksheetIcon: {
        color: '#2e2e2e',
        height: 100,
        width: 50,
        margin: "0px 20px 0px 0px"
    },
    name: {
        fontWeight: 700,
    },
    info: {
        display: "flex",
        flexDirection: 'row',
        '@media (max-width: 860px)': {
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    imgDiv: {
        flex: 1
    },
    img: {
        border: '2px gray solid',
        borderRadius: '10px',
        objectFit: 'cover',
    },
    infomation: {
        flex: 3,
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 20px'
    },
    detail: {
        color: '#2e2e2e',
        textAlign: 'justify',
        marginBottom: '30px'

    },
    button: {
        width: 200,
        borderRadius: '20px',
        fontWeight: 600,
        marginBottom: '30px'
    },
    download: {
        color: "white",

    },
    Loading: {
        width: "100%",
        height: '50vh',
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center'
    }
})
class Details extends PureComponent {
    state = {
        loading: true,
        worksheet: {}
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        fetch(`https://worksheetsapi.liamcrest.com/api/worksheet_data`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ worksheet_id: this.props.match.params.id })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                this.setState({ worksheet: data.data[0], loading: false })
            })
        console.log(this.state.worksheet)
    }

    render() {
        const { classes } = this.props
        if (this.state.loading) {
            return (
                <div className={classes.Loading}>
                    <CircularProgress />
                </div>
            )
        }
        let chips = ''
        if (this.state.worksheet !== {}) {
            // chips = this.state.worksheet.sub_categories.map((chip) => {
            //     return (
            //         <Chip key={chip.id} label={chip.name} variant="outlined" color='primary' />
            //     )
            // })
        }
        return (
            <div className={classes.Details}>
                <Typography className={classes.worksheet} variant='h3' component='p' gutterBottom><MenuBookIcon className={classes.worksheetIcon} />Worksheet</Typography>
                <Typography className={classes.name} color="primary" variant='h3' component='p' gutterBottom>{this.state.worksheet.worksheet_name}</Typography>
                <div className={classes.info}>
                    <div className={classes.imgDiv}>
                        <img className={classes.img} alt='worksheet' src={this.state.worksheet.worksheet_image_url} height='350px' width='300px' />
                    </div>
                    <div className={classes.infomation}>
                        <a className={classes.download} href={this.state.worksheet.worksheet_pdf} download={this.state.worksheet.worksheet_name}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                startIcon={<GetAppRoundedIcon />}
                            >
                                Download
                            </Button>
                        </a>
                        <a className={classes.download} href={this.state.worksheet.worksheet_pdf} download={this.state.worksheet.worksheet_name}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                startIcon={<PrintIcon />}

                            >
                                Print
                            </Button>
                        </a>
                        {this.state.worksheet.worksheet_answer_pdf &&
                            (<a className={classes.download} href={this.state.worksheet.worksheet_answer_pdf} download={this.state.worksheet.worksheet_name}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    startIcon={<GetAppRoundedIcon />}
                                >
                                    Download Answer
                                </Button>
                            </a>
                            )}

                        <Typography variant='h6'>Description</Typography>
                        <Typography variant='p' gutterBottom>{this.state.worksheet.worksheet_description}</Typography>
                        <div>

                            {chips}

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(useStyles)(Details);
import React, { Component } from 'react';
import '../Sass/Input.scss'
class Input extends Component {
    render() {
        return (
            <div className='Input'>
            <img className='InputImg' alt='icon' src={this.props.src} />
            <input id={this.props.id} className='Inputinput' type={this.props.type} name={this.props.name} placeholder={this.props.placeholder} value={this.props.value} onChange={this.props.onChange} required={this.props.required} accept={this.props.accept} disabled={this.props.disabled} min={this.props.min}/>
            </div>
        );
    }
}

export default Input;
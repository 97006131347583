import React, { PureComponent } from 'react'
import { Card , withStyles, CardContent, Typography} from '@material-ui/core'
const useStyles = (theme) => ({
card : {
    position: 'relative',
    margin: 10,
    maxWidth: 280,
    width: 300,
    // backgroundColor: '#cfc091',
    backgroundColor: '#dbdead',
    height: 350,
    '&:hover' : {
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    }
},
img : {
    margin: "10px 25px ",
    height : "280px",
    width : "220px",
},
summery : {
    visibility: 'hidden',
     maxHeight: 0,
     opacity: 0,
     transition: "max-height 0.5s , visibility 0.5s, opacity 0.5s"
},
content : {
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'white',
    width: "100%",
    height: "auto",
    '&:hover': {
        '& $summery': {
            visibility: "visible",
            maxHeight: 200,
            opacity: 1,
        }
    }
},
liam : {
    fontWeight: 600,
}
})
class Card1 extends PureComponent {
    render() {
        const { classes } = this.props
        return (
            <Card className={classes.card} id={this.props.id} >
            <div>
                <img alt='cardImg' src={this.props.src} className={classes.img}/>
            </div>
            <CardContent className={classes.content}>
          <Typography gutterBottom variant="h5" component="h5">
            {this.props.name}
          </Typography>
          {/* <Typography variant="body2" color="textSecondary" component="p" gutterBottom className={classes.summery}>
            The summery of the book
          </Typography> */}
          <Typography variant="body2" color="textSecondary" component="p" gutterBottom>WorkSheets</Typography>
          <Typography variant="body2" component="p" className={classes.liam}>By Liam Crest</Typography>
        </CardContent>
            </Card>
        );
    }
}

export default withStyles(useStyles)(Card1);
import './App.css';
import { Provider } from 'react-redux'
import Routes from './Component/Routes';
import store from './Redux/store';
import Header from './Component/Header';
import Footer from './Component/Footer';
import Footer2 from './Component/Footer2';

function App() {
  return (
    <Provider store={store}>
      
      <div className="App">
      <Header/>
        <Routes />
        <Footer/>
      <Footer2/>
      </div>
      
    </Provider>

  );
}

export default App;

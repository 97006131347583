import {CARD_LOADING, CARDS, CARD_ERROR, SET_ID, SET_PAGE, TOTAL_PAGES } from "./CardTypes"


const initialState = {
    cardLoading: false,
    cards: [],
    cardErr: false,
    page: null,
    subID: null,
    totalPages: null
}

export const cardReducer = (state = initialState, action) => {
switch(action.type){
    case CARD_LOADING:
        return {
            ...state,
            cardLoading: true
        }
        case CARDS: 
        return {
            ...state,
            cards: action.payload,
            cardLoading: false
        }
        case CARD_ERROR: 
        return {
            ...state,
            cardErr: true
        }
        case SET_PAGE: 
        return{
            ...state,
            page: action.payload,
        }
        case SET_ID: 
        return {
            ...state,
            subID: action.payload
        }
        case TOTAL_PAGES: 
        return {
            ...state,
            totalPages: action.payload
        }
        default:
            return state
}
}
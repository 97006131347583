import React, { PureComponent } from 'react'
import { Button, Container, withStyles } from '@material-ui/core'
const useStyles = () => ({
    Footer: {
        height: 'auto',
        backgroundColor: '#66f3fa',
        maxWidth: '100%'
    },
    footerHeader: {
        height: '20vh',
        width: '80%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (max-width: 1024px)': {
            // margin: '0px 30px',
            // paddingTop: '30px',
            height: '20vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        }
    },
    footerLogo: {
        height: '100px',
        '@media (max-width: 1024px)': {
            display: 'none'
        }
    },
    margin: {
        margin: '0 20px'
    },
    footerInput: {
        height: '33px',
        width: '170px',
        border: '1px grey solid',
        borderRadius: '5px',
        margin: '0 10px',
        '@media (max-width: 414px)': {
         width: "100px",
         margin: '0 5px',
        },
    },
    Img: {
        height: 35,
        width: 35,
        margin: '10px 10px'
    },
    contactMethods: {
        display: 'flex',
        alignItems: 'center',
    },
    footerAboutUs: {
        width: '70%',
        margin: '0 10px',
        '@media (max-width: 990px)': {
            width: '80%'
        },
        '@media (max-width: 600px)': {
            width: '90%'
        }
    },
    socialImg: {
        margin: '0 10px',
        '@media (max-width: 414px)': {
            height: '20px',
            width: '20px',
            margin: '0 10px'
        }
        
    },
    ThreeSec: {
       width: '90%',
       display: 'flex',
       flexDirection: 'row',
       flexWrap: "wrap" 
    },
    SecOne: {
        width:'30%',
        '@media (max-width: 990px)': {
            width: '50%'
        },
        '@media (max-width: 600px)': {
            width: '70%'
        }
        
    },
    SecTwo: {
        width:'30%',
        '@media (max-width: 990px)': {
            width: '50%'
        },
        '@media (max-width: 600px)': {
            width: '70%'
        }
    },
    footerExplore: {
        listStyleImage: "url('../footer/footer-li.png')",
        height: '70%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "space-around",
        alignItems: 'flex-start',
    },
    textDeco: {
        textDecoration: 'none',
        color: 'black',
    },
    SecThree: {
        width:'30%',
        '@media (max-width: 990px)': {
            width: '50%'
        },
        '@media (max-width: 600px)': {
            width: '70%'
        }

    },
    article: {
        margin: '10px',
        width: '90%',
        display: "flex",
        flexDirection: 'row',
        // alignItems: 'flex-start',
        '@media (max-width: 990px)': {
            width: '100%'
        },
        '@media (max-width: 600px)': {
            width: '100%'
        }
    },
    articleImg: {
        height: '110px',
        width: '150px',
    },
    articleBold: {
        fontWeight: '600',
        margin: "0 5%"
    },
    articleDate: {
        margin: "0 5%"
    }
})
class Footer extends PureComponent {
    state = {  }
    render() {
        const { classes } = this.props
        return (
           <div className={classes.Footer}>
            <Container>
            <div className={classes.footerHeader}>
            <img className={classes.footerLogo} alt='' src='/footer/footer-logo.png' />
            <div className={classes.footerButtons}>
                    <input className={classes.footerInput} type="text" name="email" placeholder="Your E - mail"/>
                    <Button variant="contained" color="primary" size='small' >SUBSCRIBE NOW</Button>

            </div>
            <div>
                <p>Follow Us</p>
                <a href="https://www.facebook.com/liamcrestcorp" target="blank"><img className={classes.socialImg} alt='' src="/footer/footer_facebook.png"/></a>
                <a href="https://www.instagram.com/liam.crest.corp" target="blank"><img className={ classes.socialImg} alt='' src="/footer/footer_instagram.png"/></a>
                    <a href="https://www.linkedin.com/company/liamcrest" target="blank"><img alt='' className={ classes.socialImg} src="/footer/footer_linkedin.png"/></a>
                    <a href="https://twitter.com/LiamCrestCorp" target="blank"><img alt='' className={ classes.socialImg} src="/footer/footer_twitter.png"/></a>
            </div>
            </div>
            <div className={classes.ThreeSec}>
            <div id="about-us" className={classes.SecOne}>
                <div className={classes.contactMethods}>
                <img className={classes.Img} alt=''  src="/footer/footer-btn.png"/>
                <h2 >About Us</h2>
                </div>
                <p className={classes.footerAboutUs}>Liam Crest is an elearning company that specializes in online training and
                    professional development. We make learning engagince, accessible, technically proficient,
                    and academically sound for learners around the world.
                </p>
                <div className={classes.contactMethods}>
                    <img className={classes.Img} alt=''  src="/footer/footer-call.png"/> 312 801 0900
                </div>
                <div className={classes.contactMethods}>
                    <img className={classes.Img} alt=''  src="/footer/footer-location.png"/> Chicago, IL and Phoenix, AZ.
                </div>
                <div className={classes.contactMethods}>
                    <img className={classes.Img} alt=''  src="/footer/footer-email.png"/> Sales@liamcrest.com
                </div>
            </div>
             <div className={classes.SecTwo}>
             <div className={classes.contactMethods}>
             <img className={classes.Img} src="/footer/footer-btn.png"/>
                <h2 >Explore</h2>
                </div>
                <ul id="footer-explore" className={classes.footerExplore}>
                    <li key={1}> <a className={classes.textDeco} href="https://liamcrest.com/#second-nav">Home</a></li>
                    <li key={2}> <a className={classes.textDeco} href="https://liamcrest.com/#about-us">About Us</a></li>
                    <li key={3}> <a className={classes.textDeco} href="https://liamcrest.com/#">Our Work</a></li>
                    <li key={4}> <a className={classes.textDeco} href="https://liamcrest.com/#footer">Contact Us</a></li>
                </ul>
             </div>
             <div className={classes.SecThree}>
             <div className={classes.contactMethods}>
             <img className={classes.Img} src="/footer/footer-btn.png"/>
                <h2 >Recent News</h2>
                </div>
                <div className={classes.article}>
                    <img className={classes.articleImg} src="/footer/footer-article2.png" />
                    <div>
                    <p className={classes.articleBold}>How to start learning as a beginner?</p>
                    <p className={classes.articleDate}>August 23, 2020</p>
                    </div>
                </div>
                <div className={classes.article}>
                    <img className={classes.articleImg} src="/footer/footer-article1.png"/>
                    <div>
                    <p  className={classes.articleBold}>How to create websites with ease? Let's start!</p>
                    <p className={classes.articleDate}>December 20, 2020</p>
                    </div>
                </div>
             </div>
            </div>
            </Container>
           </div> 
        );
    }
}

export default withStyles(useStyles)(Footer);
import React, { PureComponent } from 'react';
import FilterListTwoToneIcon from '@material-ui/icons/FilterList';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { Button, withStyles, SwipeableDrawer, Typography } from '@material-ui/core'
import '../Sass/Filter.scss'
import Accord from './Accord';
import {connect} from 'react-redux'
import { fetchFilters } from '../Redux/Filter/FilterActions';
import { fetchSubCatCards } from '../Redux/Card/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStlyes = () => ({
    nondrawer: {
        
        '@media (max-width: 1024px)' : {
            display : "none"
          }
    },
    drawer: {
        display: 'none',
        '@media (max-width: 1024px)' : {
            display : "block"
          }
    },
    filter: {
        height: 70,
        width: 40,
        margin: '0px 5px'
    },
    filterArrow: {
        padding: 10,
        margin: '0px 5px',
        color: 'grey',
        border: '2px grey solid',
        borderRadius: '50%',
        backgroundColor: 'rgb(230, 230, 230)'
    },
    filterTypo: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontWeight: 800
    },
    subLink: {
        cursor: 'pointer',
        color: '#363636',
        '&:hover': {
            fontWeight: 600,
            color: '#64cded'
        }
    },
    Loading: {
        width: "300px",
        height: '50vh',
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center'
    }
})

class Filter extends PureComponent {
    state = {
        left: false
    }
    componentDidMount(){
        this.props.fetchFilters()
    }
    toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        this.setState({ ...this.state, left: open });
      };
    render() {
        const { classes } = this.props
        const { categories, filterLoading } = this.props.Filter
        if(filterLoading){
           return (
               <div className={classes.Loading}>
              <CircularProgress />
               </div>
           )
        }
        const category = categories.map((cate) => {
            return (
                <Accord key={cate.id} id={cate.id} name={cate.category_name} >
                 {cate.sub_categories.map((sub) => {
                     return (
                         <Typography className={classes.subLink} key={sub.id} onClick={() => this.props.fetchSubCatCards(sub.id)}>
                             {sub.subcategory_name}
                         </Typography>
                     )
                 })}
                </Accord>
            )
        })
        return (
            <>
                <Button
                    variant="contained"
                    className='button'
                    color="default"
                    startIcon={<FilterListTwoToneIcon />}
                    onClick={this.toggleDrawer(true)}
                >
                    Filter By
                       </Button>
                <div className={classes.nondrawer}>
                    {category}
                </div>
                <div >
                    <SwipeableDrawer
                    className={classes.drawer}
                        anchor='left'
                        open={this.state.left}
                        onClose={this.toggleDrawer(false)}
                        onOpen={this.toggleDrawer(true)}
                    >
                         <Typography className={classes.filterTypo} variant='h5' component='p'  gutterBottom><FilterListTwoToneIcon className={classes.filter} />Filters <ArrowBackIosRoundedIcon onClick={this.toggleDrawer(false)} className={classes.filterArrow}/></Typography>
                        {category}
                    </SwipeableDrawer>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
     Filter: state.Filter,
     Card: state.Card
    }
}

const mapDispatchToProps = dispatch => {
    return {
    fetchFilters: () => dispatch(fetchFilters()),
    fetchSubCatCards: (id) => dispatch(fetchSubCatCards(id)) 
    }
  }



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStlyes)(Filter));
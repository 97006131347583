import { combineReducers } from 'redux'
import { cardReducer } from './Card/CardReducers.js'
import { filterReducer } from './Filter/FilterReducers.js'


const rootReducer = combineReducers({
    Filter: filterReducer,
    Card: cardReducer,
  })
  
  export default rootReducer
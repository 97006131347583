import { CARDS, CARD_ERROR, CARD_LOADING, SET_PAGE, SUB_CATEGORIES_CARDS, TOTAL_PAGES, SET_ID } from './CardTypes.js'



export function fetchCards() {
    return (dispatch) => {
        dispatch(cardLoading())
        dispatch(setID(null))
        fetch('https://worksheetsapi.liamcrest.com/api/fetch_worksheets',
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ limit: 10, start: 1 })
        })
            .then(res => res.json())
            .then(data => {
                dispatch(cards(data.data || []))
                dispatch(totalPages(Math.ceil(data.count / 10)))
                dispatch(setPage(1))
            })
            .catch(err => {
                dispatch(cardsError(true))
            })
    }
}



export function fetchCardsByPage(id, page) {
    return (dispatch) => {
        dispatch(cardLoading())
        if (id === null) {
            fetch('https://worksheetsapi.liamcrest.com/api/fetch_worksheets',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ limit: 10, start: page })
            })
                .then(res => res.json())
                .then(data => {

                    dispatch(totalPages(Math.ceil(data.count / 10)))
                    dispatch(setPage(Number(page)))
                    dispatch(cards(data.data || []))
                })
                .catch(err => {
                    dispatch(cardsError(true))
                })
        }
        else {
            fetch(`https://worksheetsapi.liamcrest.com/api/listf_worksheets`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ subcategory_id: id, start: page, limit: 10 })
            })
                .then(res => res.json())
                .then(data => {
                    dispatch(totalPages(Math.ceil(data.count / 10)))
                    dispatch(setPage(page))
                    dispatch(cards(data.data || []))
                })
                .catch(err => {
                    dispatch(cardsError(true))
                })
        }

    }
}


export function fetchSubCatCards(id) {
    return (dispatch) => {
        dispatch(cardLoading())
        dispatch(setID(id))
        fetch(`https://worksheetsapi.liamcrest.com/api/listf_worksheets`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ subcategory_id: id, start: 1, limit: 10 })
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === 'error') {
                    dispatch(cardsError(true))
                    dispatch(cards([]))
                } else {
                    dispatch(setPage(1))
                    dispatch(cards(data.data || []))
                    dispatch(totalPages(Math.ceil(data.count / 10)))
                }
            })
            .catch(err => {
                dispatch(cardsError(true))
            })
    }
}

export function fetchCardsBySearch(name) {
    return (dispatch) => {
        dispatch(cardLoading())
        fetch(`https://worksheetsapi.liamcrest.com/api/searching`,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ search: name })
        } )
            .then(res => res.json())
            .then(data => {
                dispatch(setPage(1))
                dispatch(cards(data.data || []))
                dispatch(totalPages(Math.ceil(data.count / 10)))
            })
            .catch(err => {
                dispatch(cardsError(true))
            })
    }
}

// export function fetchSubCatCardsByPage(id,page){
//     return (dispatch) => {
//         dispatch(cardLoading())
//         fetch(`https://awilliams.pythonanywhere.com/workbooks/books/?limit=10&offset=${page}&sub_categories__id=${id}`)
//         .then(res => res.json())
//         .then(data => {
//             dispatch(cards(data.results))

//         } )
//         .catch(err => {
//             dispatch(cardsError(true))
//         })
//     }
// }


export function totalPages(pages) {
    return {
        type: TOTAL_PAGES,
        payload: pages
    }
}
export function cards(list) {
    return {
        type: CARDS,
        payload: list
    }
}


export function cardLoading() {
    return {
        type: CARD_LOADING,
    }
}


export function cardsError(err) {
    return {
        type: CARD_ERROR,
        payload: err
    }
}


export function subCategoryCard(list) {
    return {
        type: SUB_CATEGORIES_CARDS,
        payload: list
    }
}


export function setPage(page) {
    return {
        type: SET_PAGE,
        payload: page
    }
}

export function setID(id) {
    return {
        type: SET_ID,
        payload: id
    }
}
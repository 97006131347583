import React, { PureComponent } from 'react'
import {
    BrowserRouter as Router,
    Route,
  } from "react-router-dom";
import Details from './Details';
import Login from './Login';
import Main from './Main';
import Register from './Register';
class Routes extends PureComponent {
    render() {
        return (
            <Router>
                <Route exact path='/' component={Main}/>
                <Route path = '/worksheet/:id' component={Details}/>
                <Route path = '/login' component={Login}/>
                <Route path = '/register' component = {Register}/>
            </Router>
        );
    }
}

export default Routes;
import { FILTER_ERROR, FILTER_LOADING, FILTER_RESULTS } from "./FilterTypes"

const initialState = {
    filterLoading: true,
    categories: [],
    filterError: false
}

export const filterReducer = (state = initialState, action) => {

    switch (action.type) {
        case FILTER_LOADING:
            return {
                ...state,
                filterLoading: action.payload
            }
        case FILTER_RESULTS: 
        return {
            ...state,
            categories: action.payload,
            filterLoading: false
        }
        case FILTER_ERROR: 
        return {
            ...state,
            filterError: action.payload
        }
        default:
            return state  
    }
}
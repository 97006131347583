import {FILTER_RESULTS, FILTER_LOADING, FILTER_ERROR} from './FilterTypes'

export function fetchFilters(){
    return (dispatch) => {
        dispatch(filterLoading(true))
        fetch('https://worksheetsapi.liamcrest.com/api/get_categories')
        .then(res => res.json())
        .then(data => {
            dispatch(categories(data.data))
        } )
        .catch(err => {
            dispatch(filterError(true))
        })
    }
}

export function categories(results){
    return {
        type: FILTER_RESULTS,
        payload: results
    }
}

export function filterLoading(loading){
    return {
        type: FILTER_LOADING,
        payload: loading
    }
}

export function filterError(error){
    return {
        type: FILTER_ERROR,
        payload: error
    }
}
import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles, Typography, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab'
import Input from './Input';
import axios from 'axios';
import { Redirect } from 'react-router-dom'
const useStyles = makeStyles((theme) => ({
    container: {
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    login: {
        color: '#03375e',
        backgroundColor: "white",
        border: "1px solid #03375e",
        padding: "10px 20px",
        borderRadius: "5px",
        margin: "0 5px",
    },
    register: {
        color: "white",
        backgroundColor: "#03375e",
        border: "1px solid #03375e",
        padding: "10px 20px",
        borderRadius: "5px",
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        margin: '2% 0%',
        backgroundColor: '#03375e',
        border: '2px solid #03375e',
        borderRadius: '10px',
        boxShadow: theme.shadows[5],
        width: 'clamp(20rem, 30rem, 45rem)',
        minHeight: '85vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ['@media (max-width:500px)']: {
            width: '300px',
        },
        ['@media (max-width:300px)']: {
            width: '200px',
        },
    },
    heading_color: {
        color: 'white',
        margin: '20px 0px',
    },
    login_button: {
        margin: '20px 0px',
        borderRadius: '15px',
        backgroundColor: '#ffb210',
        padding: '1% 8%',
        color: 'black',
        fontWeight: '600',
        fontFamily: `'Montserrat', sans-serif`,
        float: 'right',
        '&:hover': {
            backgroundColor: '#ffb210',
            filter: "brightness(50%)"
        }
    },
    foot: {
        width: '100%',
        minHeight: '35vh',
        backgroundImage: `url('/AdminLogin/Asset 12.png')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: '0% 10%',
        margin: '0',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end'
    },
    already_member: {
        fontWeight: '600',
        fontSize: '12px',
        textAlgin: 'center',
    },
    already_member_span: {
        color: '#1ebb90'
    },
    foot_auth: {
        textAlgin: 'center',
        fontSize: '10px',
    }
}))

const Register = (props) => {
    const classes = useStyles(props)
    const [open, setOpen] = useState(false)
    const [state, setState] = useState({})
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        })
    }
    const handleSubmit = () => {
        axios.post(`https://worksheetsapi.liamcrest.com/api/register_user`, state)
            .then((res) => {
                if (res.data.status === 'success') {
                    localStorage.setItem('token', res.data.token)
                    setState({
                        ...state,
                        redirect: true,
                    })
                    setOpen(false);
                } else if (res.data.status === 'error') {
                    setState({
                        ...state,
                        error: true,
                        errorMessage: res.data.message
                    })
                }
            })
    }
    if (state.redirect) {
        return <Redirect to='/' />
    }
    return (
        <div className={classes.container}>

            <div className={classes.paper}>
                <div>
                    <Typography className={classes.heading_color} variant='h5' component='h5'>Create Account</Typography>
                    {state.error && <Alert severity="error">{state.errorMessage} — check it out!</Alert>}
                    <Input name='user_name' type='text' placeholder='Username' value={state.user_name} onChange={handleChange} src='/AdminLogin/Asset 6.png' />
                    <Input name='email' type='text' placeholder='Email Address' value={state.email} onChange={handleChange} src='/AdminLogin/Asset 6.png' />
                    <Input name='password' type='password' placeholder='Password' value={state.password} onChange={handleChange} src='/AdminLogin/Asset 7.png' />
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.login_button}
                        onClick={handleSubmit}
                    >Create Account</Button>
                </div>
                <div className={classes.foot}>
                    <p className={classes.already_member}>Already a member? <span className={classes.already_member_span}>Login</span></p>
                    <p className={classes.foot_auth}>
                        By clicking ‘Create Account’ above, I agree that I have read and agree to LiamCrestEducation.com’s Terms of Use and Privacy Policy.
                    </p>
                    <p className={classes.foot_auth}>
                        Members receive LiamCrestEducation.com emails. You can change email preferences in account settings.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Register
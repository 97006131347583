import React, { Component } from 'react'
import {withStyles} from '@material-ui/core'
const useStyles = () => ({
    footer2: {
     height:'10vh',
     width: '100%',
     color: 'white',
     backgroundColor: '#08004d',
     display: 'flex',
     justifyContent: 'center',
     alignItems: 'center'
     
    }
})
class Footer2 extends Component {
    render() {
        const { classes } = this.props
        return (
            <div className={ classes.footer2} >
            <p> 2021 All rights reserved. Liam Crest </p>
            </div>
        );
    }
}

export default withStyles(useStyles)(Footer2);
import { AppBar, Button, IconButton, Toolbar, withStyles } from '@material-ui/core';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import React, { PureComponent } from 'react'
const useStyles = () => ({
    nav1: {
        backgroundColor: '#FFB210',
        // padding:'0 20px',
        maxWidth: '100%',
        boxSizing: 'border-box'
    },
    link: {
        margin: '0 15px'
    },
    socialMedia: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    nav2: {
        backgroundColor: 'white',
        maxWidth: '100%'
    },
    navButtons: {
        width: "80vw",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        '@media (max-width: 1024px)': {
            display: "none"
        }

    },
    navButton: {
        cursor: 'pointer',
        color: 'black',
        fontSize: '150%',
        fontWeight: '600',
        textDecoration:'none'
    },
    margin: {
        margin: '0 10px'
    },
    toggle: {
        display: "none",
        '@media (max-width: 1024px)': {
            // display: 'block',
            width: '100%',
            height: '70vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
        },
    },
    nontoggle: {
        display: 'none'
    },
    left: {
        float: 'right',
    },
    Width: {
        width: '80vw',
        '@media (min-width: 1025px)': {
            display: "none"
        }
    }

})
class Header extends PureComponent {
    state = {
        headerToggle: false
    }
    handleToggle = () => {
        this.setState({
            headerToggle: !this.state.headerToggle
        })
    }
    render() {
        const { classes } = this.props
        return (
            <>
                <AppBar key='nav1' className={classes.nav1} position='sticky'>
                    <Toolbar className={classes.socialMedia}>
                        <a className={classes.link} href="https://www.facebook.com/liamcrestcorp" target="blank"><img alt='facebook' src='/first-nav/facebook.png' /></a>
                        <a className={classes.link} href="https://twitter.com/LiamCrestCorp" target="blank"><img alt='twitter' src='/first-nav/twitter.png' /></a>
                        <a className={classes.link} href="https://www.instagram.com/liam.crest.corp" target="blank"><img alt='instagram' src='/first-nav/instagram.png' /></a>
                        <a className={classes.link} href="https://www.linkedin.com/company/liamcrest" target="blank"><img alt='linkedin' src='/first-nav/linkedin.png' /></a>
                    </Toolbar>
                </AppBar>
                <AppBar key='nav2' className={classes.nav2} position='sticky'>
                    <Toolbar>
                        <a className={classes.link} href="#"><img src='/liamcrestlogo.png' height="100" alt="Responsive image" /></a>
                        <div className={classes.navButtons}>
                            <a className={classes.navButton} href='https://liamcrest.com/#second-nav'>Home</a>
                            <a className={classes.navButton} href='https://liamcrest.com/Portfolio/'>Portfolio</a>
                            <a className={classes.navButton} href='https://liamcrest.com/#footer'>Contact Us</a>
                            {/* <div>
                                <Button variant="outlined" size="small" color="primary" className={classes.margin}>Login</Button>
                                <Button variant="contained" size="small" color="primary">Register</Button>
                            </div> */}
                        </div>
                        <div className={classes.Width}>
                            <IconButton className={classes.left} onClick={this.handleToggle}>
                                <MenuRoundedIcon fontSize="large" />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <div className={this.state.headerToggle ? classes.toggle : classes.nontoggle}>
                    <a className={classes.navButton} href='https://liamcrest.com/#second-nav'>Home</a>
                    <a className={classes.navButton} href='https://liamcrest.com/Portfolio/'>Portfolio</a>
                    <a className={classes.navButton} href='https://liamcrest.com/#footer'>Contact Us</a>
                    {/* <div>
                        <Button variant="outlined" size="small" color="primary" className={classes.margin}>Login</Button>
                        <Button variant="contained" size="small" color="primary">Register</Button>
                    </div> */}
                </div>
            </>
        )
    }
};

export default withStyles(useStyles)(Header);